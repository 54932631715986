import * as React from 'react';

import Box from '@/components/Box';

import Blockies from 'react-blockies';

import {useWeb3} from '@/web3';

import {useMediaQuery} from 'react-responsive';

import {Link} from 'react-router-dom';

import Icon from '@/assets/CryptoRugsLogo.png';
import DiscordLogoWhite from '@/assets/DiscordLogoWhite.png';
import TwitterLogoWhite from '@/assets/TwitterLogoWhite.svg';

import './NavBar.css';

export default function NavBar() {
    const {loading, isAvailable, provider, account, chainId, connect, switchChain} = useWeb3();

    const centerNav = useMediaQuery({
        query: '(max-width: 1000px)',
    });

    const superSmall = useMediaQuery({
        query: '(max-width: 440px)',
    });

    const renderLoading = () => null;
    const renderWalletInfo = () => {
        return (
            <Box className="cr-navbar-wallet-info" direction="row" alignItems="center">
                <Box className="cr-navbar-wallet-info-avatar">
                    <Blockies seed={account} size={4} scale={4} />
                </Box>
                <span className="numeric" style={{fontSize: 14}}>{account.substr(0, 6)}…{account.substr(account.length - 4, 4)}</span>
            </Box>
        );
    };

    const renderMetamaskNotInstalled = () => {
        return (
            <Box className="cr-navbar-wallet-info" direction="row" alignItems="center">
                <div style={{backgroundColor: 'red', width: 4, height: 4, borderRadius: 999, marginRight: 8}} />
                <span style={{color: 'red', fontSize: 14}}>Metamask not installed</span>
            </Box>
        );
    };

    const renderWrongChain = () => {
        return (
            <Box className="cr-navbar-wallet-info" direction="row" alignItems="center">
                <span style={{color: 'red', fontSize: 14}}>! Wrong chain</span>
            </Box>
        );
    };

    const wrongChain = chainId !== null && +chainId !== +process.env.REACT_APP_CHAIN_ID;

    React.useEffect(() => {
        if (wrongChain) {
            switchChain && switchChain();
        }
    }, [wrongChain]);

    return (
        <Box className="cr-navbar" direction={centerNav ? 'column' : 'row'} alignItems="center" justifyContent={centerNav ? 'center' : 'flex-start'}>
            <Box direction="row">
                <a href="/">
                    <Box direction="row" alignItems="center">
                        <img src={Icon} className="cr-navbar-logo-icon" />
                        <span className="cr-navbar-logo">CryptoRugs</span>
                    </Box>
                </a>
            </Box>
            <Box direction="row" style={centerNav ? {marginTop: 12, alignItems: 'center'} : {flex: 1, alignItems: 'center'}}>
                { !superSmall && (
                    <>
                        <a href="https://discord.com/invite/eUwp2zbae8" target="_blank" style={{display: 'flex', justifyContent: 'center', marginLeft: 24, marginTop: 4}}><img src={DiscordLogoWhite} style={{width: 16, height: 18}} /></a>
                        <a href="https://twitter.com/thecryptorugs" target="_blank" style={{display: 'flex', justifyContent: 'center', marginLeft: 20, marginTop: 2}}><img src={TwitterLogoWhite} style={{width: 16, height: 18}} /></a>
                    </>
                )}
                <Link to="/my">My Rugs</Link>
                <a href="https://etherscan.io/address/0x7685d54db7b41dd751ad1e1b62a62f92d3ce09be" target="_blank">Etherscan →</a>
                <a href="https://opensea.io/collection/thecryptorugs" target="_blank">OpenSea →</a>
                {/*<Link to="/about">About</Link>*/}
                {!centerNav && (
                    <>
                        <div className="spacer" />
                        {!loading && !isAvailable && renderMetamaskNotInstalled()}
                        {!loading && isAvailable && wrongChain && renderWrongChain()}
                        {!loading && isAvailable && !wrongChain && (account ? renderWalletInfo() : <button className="cr-navbar-connect" onClick={() => connect()}>Connect Wallet</button>)}
                    </>
                )}
            </Box>
            { superSmall && (
                <Box direction="row" style={{marginTop: 8}}>
                    <a href="https://discord.com/invite/eUwp2zbae8" target="_blank" style={{display: 'flex', justifyContent: 'center', marginTop: 4, marginLeft: 0}}><img src={DiscordLogoWhite} style={{width: 16, height: 18}} /></a>
                    <a href="https://twitter.com/thecryptorugs" target="_blank" style={{display: 'flex', justifyContent: 'center', marginTop: 2}}><img src={TwitterLogoWhite} style={{width: 16, height: 18}} /></a>
                </Box>
            )}
            { centerNav && (
                <Box style={{marginTop: 8}}>
                    <div className="spacer" />
                    {!loading && !isAvailable && renderMetamaskNotInstalled()}
                    {!loading && isAvailable && wrongChain && renderWrongChain()}
                    {!loading && isAvailable && !wrongChain && (account ? renderWalletInfo() : <button className="cr-navbar-connect" onClick={() => connect()}>Connect Wallet</button>)}
                </Box>
            )}
        </Box>
    );
}