import Box from '@/components/Box';

export default function SaleTermsScene() {
    return (
        <Box style={{paddingLeft: 64, paddingRight: 64, paddingTop: 16, paddingBottom: 16}}>
            <span style={{fontSize: 32, marginBottom: 16, fontWeight: 'bold'}}>Sale Terms</span>
            <span>
            CryptoRugs is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing participants to purchase and view their CryptoRugs digital collectibles (each referred to as a "Crypto Rug"). Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the CryptoRugs smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.

            This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole responsibility for any and all transactions involving CryptoRugs digital collectibles.
            </span>

            <span style={{fontSize: 24, marginTop: 20, marginBottom: 8}}>OWNERSHIP</span>
            <span>
                i. You Own the NFT. Each Crypto Rug is an NFT on the Ethereum blockchain. When you purchase an NFT from CryptoRugs, you own the underlying Crypto Rug, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Ethereum Network: at no point may we seize, freeze, or otherwise modify the ownership of any Crypto Rug.<br />
                <br />
                ii. Personal Use. Subject to your continued compliance with these Terms, the CryptoRugs developers grant you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; or (ii) as part of a third party website or application that permits the inclusion, involvement, or participation of your Crypto Rug, provided that the website/application cryptographically verifies each Crypto Rug owner’s rights to display the Art for their Crypto Rug to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Crypto Rug leaves the website/application.<br />
                <br />
                iii. Commercial Use. Subject to your continued compliance with these Terms, the CryptoRugs developers grant you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating a third party website or application that permits the inclusion, involvement, or participation of CryptoRugs generally, provided that the third party website or application cryptographically verifies each Crypto Rug owner’s rights to display the Art for their Crypto Rug to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased Crypto Rug leaves the website/application; or (ii) earning revenue from any of the foregoing.
            </span>
        </Box>
    );
}