import * as React from 'react';

import Box from '@/components/Box';

import {ethers} from 'ethers';

import {useWeb3} from '@/web3';
import {makeCryptoRugsContract} from '@/web3/contracts/CryptoRugs';

import {useMediaQuery} from 'react-responsive';

import toast, { Toaster } from 'react-hot-toast';
import Marquee from 'react-fast-marquee';

import HomeBanner from '@/assets/HomeBanner.png';
import AlphaHomora from '@/assets/AlphaHomora.png';
import BloodRug from '@/assets/BloodRug.png';
import BunnyRug from '@/assets/BunnyRug.png';
import GoldRug from '@/assets/GoldRug.png';
import HarvestRug from '@/assets/HarvestRug.png';
import PepeRug from '@/assets/PepeRug.png';
import PickleRug from '@/assets/PickleRug.png';
import PoopRug from '@/assets/PoopRug.png';
import SpartanRug from '@/assets/SpartanRug.png';
import UnrevealedRug from '@/assets/UnrevealedRug.png';
import WojakRug from '@/assets/WojakRug.png';

import './HomeScene.css';
import {Link} from 'react-router-dom';

function millisecondsToDaysHoursMinutesSeconds(ms) {
    ms += 999; // add 999ms to round remaining milliseconds up to the next second
    const days = Math.floor(ms / (24 * 1000 * 60 * 60));
    const hours = Math.floor(ms / (1000 * 60 * 60)) % 24;
    const minutes = Math.floor(ms / (1000 * 60)) % 60;
    const seconds = Math.floor(ms / 1000) % 60;

    return [days, hours, minutes, seconds];
}

function leadingZero(num) {
    return num >= 0 && num < 10 ? '0' + num : +num;
}

export default function HomeScene() {
    const initialSaleTimeRemaining = process.env.REACT_APP_SALE_START_TIME - Date.now();
    const [timeRemainingMs, setTimeRemainingMs] = React.useState(initialSaleTimeRemaining);
    const [currentSupply, setCurrentSupply] = React.useState(null);
    const [isSaleActive, setIsSaleActive] = React.useState(null);
    const [inputNumMint, setInputNumMint] = React.useState('');
    const [error, setError] = React.useState(null);

    const {loading, isAvailable, provider, account, connect} = useWeb3();

    const contractRef = React.useRef(null);
    const refreshSupplyTimeoutRef = React.useRef(null);

    const isMobile = useMediaQuery({
        query: '(max-width: 1000px)',
    });

    const isSuperSmall = useMediaQuery({
        query: '(max-width: 500px)',
    });

    const isSuperDuperSmall = useMediaQuery({
        query: '(max-width: 384px)',
    });

    const updateTimeRemainingMs = () => {
        const timeRemainingMs = process.env.REACT_APP_SALE_START_TIME - Date.now();
        setTimeRemainingMs(Math.max(0, timeRemainingMs));
        if (timeRemainingMs > 0) {
            setTimeout(updateTimeRemainingMs, 1000);
        }
    };

    const updateCurrentSupply = async () => {
        try {
            const [supply, isSaleActive] = await Promise.all([
                contractRef.current.totalSupply(),
                contractRef.current.saleIsActive(),
            ]);
            setCurrentSupply(supply);
            setIsSaleActive(isSaleActive);
        } catch (err) { }

        if (currentSupply < 5555) {
            refreshSupplyTimeoutRef.current  = setTimeout(updateCurrentSupply, 5000);
        }
    };

    React.useEffect(() => {
        updateTimeRemainingMs();
    }, []);

    React.useEffect(() => {
        if (!provider) return;
        contractRef.current = makeCryptoRugsContract(provider);
        if (account) {
            const signer = provider.getSigner();
            contractRef.current = contractRef.current.connect(signer);
        }
        refreshSupplyTimeoutRef.current && clearTimeout(refreshSupplyTimeoutRef.current);
        updateCurrentSupply();
    }, [provider, account]);

    const [daysRemaining, hoursRemaining, minutesRemaining, secondsRemaining] = millisecondsToDaysHoursMinutesSeconds(timeRemainingMs);

    const renderSaleCountdownOrRemainingSupply = () => {
        if (timeRemainingMs <= 0) {
            if (loading) return <span style={{color: 'grey'}}>Loading sale status...</span>;
            if (!isAvailable) {
                return (
                    <a href="https://metamask.io/" target="_blank" style={{textDecoration: 'none'}}><span style={{color: '#f6851b', cursor: 'pointer'}}>Install MetaMask to mint →</span></a>
                );
            }
            if (currentSupply === null || isSaleActive === null) return <span style={{color: 'grey'}}>Loading sale status...</span>;

            if (currentSupply.toString() === '5555') {
                return (
                    <span style={{color: 'red'}}>Sold out.</span>
                );
            } else {
                if (isSaleActive) {
                    return (
                        <span><span style={{color: 'red'}}>{5555 - currentSupply.toNumber()}</span> remaining.</span>
                    );
                } else {
                    return (
                        <span style={{color: 'yellow'}}>Minting starts momentarily.</span>
                    );
                }
            }
        } else {
            return (
                <span>
                    Sale begins in <span className="cr-homescene-countdown-time">{daysRemaining}d {leadingZero(hoursRemaining)}h {leadingZero(minutesRemaining)}m {leadingZero(secondsRemaining)}s</span>.
                </span>
            );
        }
    };

    const onInputNumMintChange = (ev) => {
        if (ev.target.value === '') {
            setInputNumMint('');
            return;
        }
        const num = +ev.target.value;
        if (isNaN(num)) {
            return;
        }
        if (num < 1 || num > 30) {
            return;
        }
        setInputNumMint(num);
    };

    const onMintClick = async () => {
        if (!contractRef.current) return;
        const numToMint = +inputNumMint;
        if (isNaN(numToMint) || numToMint < 1 || numToMint > 30) return;

        const total = ethers.utils.parseEther('0.05').mul(numToMint);

        try {
            const tx = await contractRef.current.mint(
                numToMint,
                {
                    value: total,
                }
            );
            toast.promise(
                tx.wait(),
                {
                    loading: (
                        <span>Transaction pending...<br /><a style={{textDecoration: 'underline', color: 'blue'}} href={`https://etherscan.io/tx/${tx.hash}`} target="_blank">View on Etherscan →</a></span>
                    ),
                    success: <span><span style={{fontWeight: 'bold'}}>Success!</span><br /><a style={{textDecoration: 'underline', color: 'blue'}} href={`https://etherscan.io/tx/${tx.hash}`} target="_blank">View on Etherscan →</a></span>,
                    error: <span><span style={{fontWeight: 'bold'}}>Transaction error</span><br /><a style={{textDecoration: 'underline', color: 'blue'}} href={`https://etherscan.io/tx/${tx.hash}`} target="_blank">View on Etherscan →</a></span>,
                },
            );
        } catch (err) {
            if (err.message.includes('insufficient funds')) {
                setError('Insufficient wallet balance');
            } else {
                setError(err.message);
            }
        }
    };

    const renderMintFormIfSaleActive = () => {
        if (!isSaleActive) return null;

        const numToMint = +inputNumMint;
        const mintButtonDisabled = isNaN(numToMint) || numToMint < 1 || numToMint > 30;
        const total = !mintButtonDisabled && ethers.utils.parseEther('0.05').mul(numToMint);

        return (
            <Box style={{marginTop: 8}}>
                {error && <span style={{fontSize: 12, fontWeight: 'bold', color: 'red', marginBottom: 4}}>{error}</span>}
                <Box direction="row">
                    <input
                        className="cr-homescene-mint-input"
                        type="text"
                        placeholder="Number to mint"
                        value={inputNumMint}
                        onChange={onInputNumMintChange}
                        style={{flex: 1}} />
                    {account && (
                        <button className={mintButtonDisabled ? 'cr-homescene-mint-disabled' : 'cr-homescene-mint'} disabled={mintButtonDisabled} onClick={onMintClick}>
                            Mint{total && ` (${ethers.utils.formatEther(total)}Ξ)`}
                        </button>
                    )}
                    {!account && (
                        <button className="cr-homescene-connect" onClick={() => connect()}>
                            Connect Wallet
                        </button>
                    )}
                </Box>
                <Box style={{marginTop: 8}} direction="row">
                    <span style={{fontSize: 12, fontWeight: 'bold', color: 'grey'}}><span style={{whiteSpace: 'nowrap'}}>Max 30 per transaction.</span> <span style={{whiteSpace: 'nowrap'}}><Link target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} to="/terms">Sale Terms</Link> & <Link target="_blank" style={{color: 'inherit', textDecoration: 'underline'}} to="/odds-disclosure">Odds Disclosure</Link></span></span>
                </Box>
            </Box>
        );
    };

    const tickerImages = [
        BunnyRug,
        HarvestRug,
        PickleRug,
        PoopRug,
        SpartanRug,
    ];

    return (
        <Box className="cr-homescene">
            <Box className="cr-homescene-banner" direction={isMobile ? 'column' : 'row'} alignItems="center">
                <Box flex={1} style={isMobile ? {} : {width: 0, marginRight: 8}} alignItems="flex-end">
                    <Box style={{maxWidth: 540, textAlign: isMobile ? 'center' : 'left'}} alignItems={isMobile ? 'center' : 'flex-start'}>
                        <span className="cr-homescene-title">Crypto's <span style={{color: 'red'}}>biggest</span> rugs.<br />Now as NFTs.</span>
                        <span className="cr-homescene-subtitle">CryptoRugs is a limited collection of 5555 stylish and unique digital rugs. Each rug commemorates a noteworthy exploit, hack, or rugpull in cryptocurrency history.</span>
                        <span className="cr-homescene-countdown"><span className="cr-homescene-countdown-price" style={{whiteSpace: 'nowrap'}}>0.05Ξ</span> each. <span style={{whiteSpace: 'nowrap'}}>{renderSaleCountdownOrRemainingSupply()}</span></span>
                        {renderMintFormIfSaleActive()}
                    </Box>
                </Box>
                <Box flex={1} direction="row" style={isMobile ? {marginTop: 16} : {width: 0, marginLeft: 8}}>
                    <img src={HomeBanner} style={{flex: 1, maxWidth: '100%', height: 'auto'}} />
                </Box>
            </Box>
            <Box className="cr-homescene-showroom" style={{overflow: 'hidden'}}>
                <Box>
                    {/*<span className="cr-homescene-title" style={{marginBottom: 48, textAlign: 'center'}}>The Showroom</span>*/}
                    <Box>
                        <Marquee speed={75} gradient={false}>
                            <img src={AlphaHomora} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={BloodRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={BunnyRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={GoldRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={HarvestRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={PepeRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={PickleRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={PoopRug} className="cr-homescene-marquee-img" style={{ marginRight: 32}} />
                            <img src={SpartanRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={WojakRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={AlphaHomora} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={BloodRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={BunnyRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={GoldRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={HarvestRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={PepeRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={PickleRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={PoopRug} className="cr-homescene-marquee-img" style={{ marginRight: 32}} />
                            <img src={SpartanRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                            <img src={WojakRug} className="cr-homescene-marquee-img" style={{marginRight: 32}} />
                        </Marquee>
                    </Box>
                </Box>
            </Box>
            <Box className="cr-homescene-socials" alignItems="center">
                <span className="cr-homescene-info-title">Dropping 8/28 at 8:00pm UTC.</span>
                <span style={{textAlign: 'center', lineHeight: 1.5}}>Don't miss out!<br />Join us on <a href="https://discord.com/invite/eUwp2zbae8" target="_blank" className="cr-homescene-discord-link">Discord</a> and follow us on <a href="https://twitter.com/thecryptorugs" target="_blank" className="cr-homescene-twitter-link">Twitter</a> to stay in the loop.</span>
                <Box direction="row" style={{marginTop: 32}}>
                    <img src={UnrevealedRug} style={{width: 256}}/>
                </Box>
            </Box>
            <Toaster position="top-right" />
        </Box>
    );
}