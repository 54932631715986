import classNames from 'classnames';

import './Box.css';

export default function Box({className, flex, direction, justifyContent, alignItems, children, style, ...rest}) {
    return (
        <div className={classNames('cr-box', className)} style={{flex, flexDirection: direction, justifyContent, alignItems, ...style}} {...rest}>
            {children}
        </div>
    );
}