import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import { Web3Provider } from '@/web3';

import NavBar from '@/components/NavBar';
import Footer from '@/components/Footer';
import HomeScene from '@/components/HomeScene';
import MyRugsScene from '@/components/MyRugsScene';
import OddsDisclosure from '@/components/OddsDisclosure';
import SaleTermsScene from '@/components/SaleTermsScene';

import './App.css';

function App() {
  return (
      <Web3Provider>
        <Router>
          <div className="App">
            <div className="content">
                <Router>
                    <NavBar />
                    <Switch>
                        <Route path="/odds-disclosure" exact>
                            <OddsDisclosure />
                        </Route>
                        <Route path="/my" exact>
                            <MyRugsScene />
                        </Route>
                        <Route path="/terms" exact>
                            <SaleTermsScene />
                        </Route>
                        <Route path="/" exact>
                            <HomeScene />
                        </Route>
                    </Switch>
                </Router>
            </div>
            <Footer />
          </div>
        </Router>
      </Web3Provider>
  );
}

export default App;
