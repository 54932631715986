import * as React from 'react';
import { ethers } from "ethers";

const ready = new Promise(function(resolve, reject) {
    if (document.readyState === 'complete') {
        resolve();
    }
    window.addEventListener('load', function() {
        resolve();
    });
});

async function getWeb3() {
    await ready;

    const isAvailable = !!window.ethereum;
    if (!isAvailable) {
        return {
            isAvailable,
            provider: null,
            connected: false,
            connect: null,
        };
    }
    const provider = isAvailable && new ethers.providers.Web3Provider(window.ethereum);

    return {
        isAvailable,
        provider,
    };
}

const checksumEthAddress = (address) => ethers.utils.getAddress(address);

const Web3Context = React.createContext(null);

export function Web3Provider({children}) {
    const [loading, setLoading] = React.useState(true);
    const [web3, setWeb3] = React.useState({loading: true});
    const [account, setAccount] = React.useState(null);

    const connectWeb3 = async (provider) => {
        if (account) {
            return;
        }
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const address = checksumEthAddress(await signer.getAddress());
        localStorage.setItem('web3_connected', true);
        window.ethereum.on('accountsChanged', (accounts) => {
            const account = accounts[0];
            if (account) {
                setAccount(checksumEthAddress(accounts[0]));
            } else {
                localStorage.removeItem('web3_connected');
                setAccount(null);
            }
        });
        return address;
    };

    React.useEffect(() => {
        async function loadWeb3() {
            const web3 = await getWeb3();
            if (web3.provider) {
                const network = await web3.provider.getNetwork();
                web3.chainId = network.chainId;
            } else {
                web3.chainId = null;
            }
            setWeb3(web3);
            if (localStorage.getItem('web3_connected')) {
                const address = await connectWeb3(web3.provider);
                setAccount(address);
            }
            setLoading(false);
        }
        loadWeb3();
    }, []);

    const connect = web3.provider && (async () => {
        const address = await connectWeb3(web3.provider);
        setAccount(address);
    });

    const switchChain = window.ethereum && (async () => {
        const chainId = '0x'+(+process.env.REACT_APP_CHAIN_ID).toString(16);
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId }], // chainId must be in hexadecimal numbers
        });
        // FIXME: HACK
        // window.location.reload();
    });

    return (
        <Web3Context.Provider value={{...web3, loading, account, connect: connect || null, switchChain: switchChain || null}}>
            {children}
        </Web3Context.Provider>
    );
}

export const useWeb3 = () => React.useContext(Web3Context);
