import * as React from 'react';

import {Link} from 'react-router-dom';

import {useWeb3} from '@/web3';

import Box from '@/components/Box';
import {makeCryptoRugsContract} from '@/web3/contracts/CryptoRugs';
import {load} from 'dotenv';

export default function MyRugsScene() {
    const [rugs, setRugs] = React.useState([]);
    const [rugMetadata, setRugMetadata] = React.useState({});
    const [loadingRugs, setLoadingRugs] = React.useState(false);

    const {loading, isAvailable, provider, account, connect} = useWeb3();

    React.useEffect(() => {
        if (!provider || !account) {
            setRugs([]);
            return;
        }
        async function loadRugs() {
            setLoadingRugs(true);

            const cryptoRugs = makeCryptoRugsContract(provider).connect(provider.getSigner());
            const numTokens = await cryptoRugs.balanceOf(account);

            let tokenPromises = [];
            for (let i = 0; i < numTokens; i++) {
                tokenPromises.push(cryptoRugs.tokenOfOwnerByIndex(account, i));
            }
            const tokens = await Promise.all(tokenPromises);
            const metadataFetchPromises = tokens.map(async (token) => {
                const respJson = await fetch(`https://cryptorugs.org/meta/tokens/${token.toString()}/metadata.json`);
                const resp = await respJson.json();
                return [token.toString(), resp];
            });
            let metadataIdx = {};
            try {
                const tokensMetadata = await Promise.all(metadataFetchPromises);
                for (const [tokenId, tokenMetadata] of tokensMetadata) {
                    metadataIdx[tokenId] = tokenMetadata;
                }
            } catch { }

            setRugs(tokens.map((tokenId) => tokenId.toString()));
            setRugMetadata(metadataIdx);
            setLoadingRugs(false);
        }
        loadRugs();
    }, [provider, account]);

    return (
        <Box alignItems="center" style={{marginTop: 16, marginBottom: 16}}>
            <span style={{fontWeight: 'bold', fontSize: 32, marginBottom: 16}}>Your Rugs</span>
            {loadingRugs && <span style={{fontWeight: 'bold'}}>Loading...</span>}
            {!loadingRugs && rugs.length === 0 && <span>You have no rugs. <Link to="/" style={{color: 'blue', fontWeight: 'bold', textDecoration: 'none'}}>Go mint some!</Link></span>}
            <Box direction="row" style={{flexWrap: 'wrap'}} justifyContent="center">
                {rugs.map((tokenId) => {
                    const metadata = rugMetadata[tokenId];

                    return (
                        <Box alignItems="center" style={{padding: 8}} key={tokenId}>
                            <img src={`https://cryptorugs.org/meta/tokens/${tokenId}/image.png`} style={{width: 200, height: 'auto'}}/>
                            {metadata && <span style={{fontWeight: 'bold', fontSize: 14, marginTop: 8}}>{metadata.name}</span>}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    )
}