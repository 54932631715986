import Box from '@/components/Box';

import './Footer.css';

export default function Footer() {
    return (
        <Box className="cr-footer" alignItems="center" justifyContent="center">
            <span style={{fontWeight: 'bold'}}>&copy; 2021 CryptoRugs. All rights reserved.</span>
            <span></span>
        </Box>
    );
}